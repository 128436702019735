.carousel {
  width: 100%;
  height: 76vh;
}

.carousel-inner {
  margin-bottom: 3vh;
  width: 100%;
  height: 100%;
}

.carousel-item {
  left: 12.5%;
  display: block;
  max-width: 75vw;
  height: 100%;
  object-fit: contain;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.carousel-control-prev-icon {
  /* background-image: url('/general/prev.png'); */
  width: 5rem;
  height: 5rem;
}

.carousel-control-next-icon {
  /* background-image: url('/general/next.png'); */
  width: 5rem;
  height: 5rem;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1vw;
  margin-bottom: 0;
  bottom: -4vw;
}

.carousel-indicators [data-bs-target] {
  border-top: 0;
  border-bottom: 0;
  background-color: rgba(247 247 247 / 0.5);
  width: 2vw;
  height: 2vw;
  border-radius: 0.5vw;
  transition: opacity 0.6s linear, border-radius 0.6s linear, width 0.6s linear, height 0.6s linear, background-color 0.6s linear;
}

.carousel-indicators .active {
  width: 3vw;
  height: 3vw;
  border-radius: 0.75vw;
  background-color: rgba(81 134 115 / 1);
}
